import React from 'react';

const IconLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g id="Logo" transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <path
          d="M52.6,59.9c0.1,0.3,0.2,0.6,0.2,0.9c0,0.2,0,0.4-0.2,0.5c-0.2,0.1-0.4,0.2-0.6,0.2c-0.3,0-0.7,0-1.2,0s-0.9,0-1.2,0  c-0.2,0-0.5,0-0.7-0.1c-0.1,0-0.3-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.2-0.4l-2.7-7.7H32.4l-2.6,7.6c0,0.1-0.1,0.3-0.2,0.4  c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.5,0.1-0.7,0.2c-0.3,0-0.7,0-1.1,0c-0.4,0-0.8,0-1.2-0.1c-0.2,0-0.4-0.1-0.6-0.2  c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.3,0.1-0.6,0.2-0.9l10.6-29.4c0-0.2,0.1-0.3,0.3-0.4c0.1-0.1,0.3-0.2,0.5-0.3c0.3-0.1,0.5-0.1,0.8-0.2  c0.3,0,0.7,0,1.2,0c0.5,0,0.9,0,1.3,0c0.3,0,0.6,0.1,0.8,0.1c0.2,0,0.4,0.1,0.5,0.3c0.1,0.1,0.2,0.3,0.3,0.5L52.6,59.9z M38.9,34  L38.9,34l-5.5,15.8h11L38.9,34z"
          fill="#64FFDA"
        />
        <polygon
          id="Shape"
          stroke="#64FFDA"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
